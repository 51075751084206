<template>
    <div class="company__logo">
        <img
            v-if="logo"
            :src="logo"
            alt=""
            class="company__logo-img"
        >
        <span
            v-else
            class="company__logo-letter"
        >
            {{companyName[0]}}
        </span>
    </div>
</template>

<script>
export default {
    name: "Logo",
    props: ['logo', 'companyName'],
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";
.company__logo {
    @include hard-size(26px, 26px);
    &-img {
        height: 100%;
        width: 100%;
        border-radius: 3px;
    }
    &-letter {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        background: #D8DDEF;
        color: #767F9C;
        font-weight: 700;
    }
}
</style>
